<template>
  <div class="mb10">
    <h2 class="content_title">
      {{ $t('msg.SETT010T010.107') }} <!-- 결제 정보 -->
    </h2>
    <div :style="headerFooterCss">
      <table class="tbl_col">
        <colgroup>
          <col style="width:22%">
          <col style="width:22%">
          <col style="width:20%">
          <col style="width:20%">
          <col style="width:16%">
        </colgroup>
        <thead>
          <tr>
            <th class="bb">B/L No.</th>
            <th class="bb">{{ $t('msg.ADD20220219.017') }}</th> <!-- 통화 선택-->
            <th class="bb">Currency</th>
            <th class="bb">{{ $t('msg.CSDBL400_M2.0042') }} </th> <!-- 결제 정보 -->
            <th class="bb">Invoice</th>
          </tr>
        </thead>
      </table>
    </div>
    <div id="fbp-body-table-div">
      <table class="tbl_col">
        <tbody>
          <template v-for="(row, i) in info">
            <tr :key="'fbp-row1-' + i">
              <td class="bb" rowspan="2" style="width:22%">{{ row.blNo }}</td>
              <td class="bb" rowspan="2" style="width:22%">
                <select v-model="row.currency" @change="cahngeCurrency(i)">
                  <option v-for="(c, j) in currency" :value="c" :key="'fpb-row-' + i + '-select-option-' + j">{{ c }}</option>
                </select>
              </td>
              <td style="width:20%">KRW</td>
              <!-- <td class="text_right" style="width:20%">{{ numberFormat(row.exKrw) }}</td> -->
              <td class="text_right" style="width:20%">
                <template v-if="row.isCollectBl">
                  **
                </template>
                <template v-else>
                  {{ numberFormat(row.exKrw) }}
                </template>
              </td>
              <td class="bb" rowspan="2" style="width:16%">
                <template v-if="!isEmpty(row.invIssNo) && !isEmpty(row.invRno) && !row.isCollectBl">
                  <a class="button sm" href="javascript:void(0)" @click="openInvoicePop(i)">{{ $t('msg.MYIN040G010.029') }}</a><!-- 출력 -->
                </template>
                <template v-if="!isEmpty(row.invIssNo) && !isEmpty(row.invRno) && row.isCollectBl">
                  <a class="button sm" href="javascript:void(0)" style="background-color: darkgray;">{{ $t('msg.MYIN040G010.029') }}</a><!-- 출력 -->
                </template>
              </td>
            </tr>
            <tr :key="'fbp-row2-' + i">
              <td class="border_left bb" style="width:20%">USD</td>
              <!-- <td class="text_right bb" style="width:20%">{{ numberFormat(row.exUsd) }}</td> -->
              <td class="text_right bb" style="width:20%">
                <template v-if="row.isCollectBl">
                  **
                </template>
                <template v-else>
                  {{ numberFormat(row.exUsd) }}
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div :style="headerFooterCss">
      <table class="tbl_col">
        <colgroup>
          <col style="width:22%">
          <col style="width:22%">
          <col style="width:20%">
          <col style="width:20%">
          <col style="width:16%">
        </colgroup>
        <tfoot>
          <tr>
            <th rowspan="2">Total</th>
            <th rowspan="2">B/L {{ info.length }}{{ $t('msg.UTIL010G010.010') }}</th>
            <th>KRW</th>
            <th class="text_right">{{ numberFormat(krwTotal) }}</th>
            <th rowspan="2"></th>
          </tr>
          <tr>
            <th class="border_left">USD</th>
            <th class="text_right">{{ numberFormat(usdTotal) }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
    <invoice-publish-pop ref="invoicePub" />
  </div>
</template>

<script>
import CalcIssue from '@/api/rest/settle/calcIssue'
import InvoicePublishPop from '@/pages/settle/popup/InvoicePublishPop'
export default {
  name: 'FreightBillInfo',
  components: {
    InvoicePublishPop
  },
  props: [
    'blList',
    'dpId',
    'collectBlList'
  ],
  data: function () {
    return {
      currency: ['KRW', 'KRW+USD'],
      krwTotal: 0,
      usdTotal: 0,
      info: [],
      headerFooterCss: {
        paddingRight: '0px'
      }
    }
  },
  computed: {
  },
  watch: {
    blList: {
      deep: true,
      handler () {
        if (this.blList !== undefined) {
          this.parseFreightBillInfo()
        }
      }
    }

  },
  created () {},
  mounted: function () {
    if (this.blList !== undefined) {
       this.parseFreightBillInfo()
    }
  },
  methods: {
    openInvoicePop (idx) {
      this.$store.commit('settle/updateIsInvoicePublishPop', false)

      let invIssNoArr = this.blList[idx].invIssNo.split(',')
      let invRnoArr = this.blList[idx].invIssNo.split(',')
      let arr = []
      for (let i = 0; i < invIssNoArr.length; i++) {
        arr.push({
          invIssNo: invIssNoArr[i],
          blNo: this.blList[idx].blNo,
          invRno: invRnoArr[i],
          index: 0 // 없으면 0
        })
      }
      $('#layer-invoice-publish > .popup_dim').fadeOut()
      $('body, html').removeAttr('style')
      this.$refs.invoicePub.openInvoicePop(arr, this.blList[idx].eiCatCd)
    },
    // openInvoicePop (idx) {
    //   this.$store.commit('settle/updateIsInvoicePublishPop', false)

    //   let items = []

    //   items.push({
    //     blNo: this.blList[idx].blNo,
    //     remark: ''
    //   })

    //   const params = {
    //     eiCatCd: 'O',
    //     items: items
    //   }

    //   $('#layer-invoice-publish > .popup_dim').fadeOut()
    //   $('body, html').removeAttr('style')

    //   this.openPopup('CombineInvoicePop', params)
    // },
    // async parseFreightBillInfo () {
    //   await this.recalculateFreightBillInfo()

    //   let tempInfo = JSON.parse(JSON.stringify(this.blList))
    //   let collectTempInfo = null

    //   if (typeof this.collectBlList !== 'undefined' && this.collectBlList !== null) {
    //     collectTempInfo = JSON.parse(JSON.stringify(this.collectBlList))
    //   }

    //   for (let i = 0; i < tempInfo.length; i++) {
    //     tempInfo[i].exKrw = tempInfo[i].krw
    //     tempInfo[i].exUsd = tempInfo[i].usd
    //   }

    //   if (collectTempInfo !== null) {
    //     if (collectTempInfo.length > 0) {
    //       let isExistCollectBl = false

    //       for (let i = 0; i < tempInfo.length; i++) {
    //         isExistCollectBl = false
    //         for (let j = 0; j < collectTempInfo.length; j++) {
    //           if (tempInfo[i].blNo === collectTempInfo[j].blNo && collectTempInfo[j].actShprCstEnm === '**') {
    //             tempInfo[i].exKrw = 0
    //             tempInfo[i].exUsd = 0
    //             tempInfo[i].krw = 0
    //             tempInfo[i].usd = 0
    //             isExistCollectBl = true
    //             break
    //           }
    //         }

    //         if (!isExistCollectBl) {
    //           continue
    //         }
    //       }
    //     }
    //   }

    //   this.info = tempInfo

    //   for (let i = 0; i < this.info.length; i++) {
    //     this.cahngeCurrency(i)
    //   }

    //   if (this.info.length > 5) {
    //     this.headerFooterCss = {
    //         paddingRight: '3px'
    //     }
    //   } else {
    //      this.headerFooterCss = {
    //         paddingRight: '0px'
    //     }
    //   }
    //   this.taxTemplateSelect = 9999
    //   this.calcTotal()
    // },
    async parseFreightBillInfo () {
      let tempInfo = JSON.parse(JSON.stringify(this.blList))
      let collectTempInfo = null

      if (typeof this.collectBlList !== 'undefined' && this.collectBlList !== null) {
        collectTempInfo = JSON.parse(JSON.stringify(this.collectBlList))
      }

      if (collectTempInfo !== null) {
        if (collectTempInfo.length > 0) {
          let isExistCollectBl = false

          for (let i = tempInfo.length - 1; i >= 0; i--) {
            isExistCollectBl = false
            for (let j = 0; j < collectTempInfo.length; j++) {
              if (tempInfo[i].blNo === collectTempInfo[j].blNo) {
                tempInfo[i].exKrw = 0
                tempInfo[i].exUsd = 0
                tempInfo[i].krw = 0
                tempInfo[i].usd = 0
                isExistCollectBl = true
                break
              }
            }

            tempInfo[i].isCollectBl = isExistCollectBl
          }
        }
      }

      for (let i = 0; i < tempInfo.length; i++) {
        tempInfo[i].exKrw = tempInfo[i].krw
        tempInfo[i].exUsd = tempInfo[i].usd
      }

      this.info = tempInfo

      await this.recalculateFreightBillInfo()

      for (let i = 0; i < this.info.length; i++) {
        this.cahngeCurrency(i)
      }

      if (this.info.length > 5) {
        this.headerFooterCss = {
            paddingRight: '3px'
        }
      } else {
         this.headerFooterCss = {
            paddingRight: '0px'
        }
      }
      this.taxTemplateSelect = 9999
      this.calcTotal()
    },
    calcTotal () {
        this.krwTotal = 0
        this.usdTotal = 0
        for (let row of this.info) {
            this.krwTotal += row.exKrw
            this.usdTotal += row.exUsd
            //권한 없는 콜렉트 비엘은 토탈 금액 표시 안되도록 변경
            console.log('row.isCollectBl+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++', row.isCollectBl)
            if (row.isCollectBl) {
              this.krwTotal = 0
              this.usdTotal = 0
            }
        }
    },
    async cahngeCurrency (idx) {
      let currency = this.info[idx].currency
      let exKrw = 0
      let exUsd = 0
      let arrBlNo = []
      if (this.$ekmtcCommon.isEmpty(this.info[idx].billLocAmt)) {
        arrBlNo.push(this.info[idx].blNo)
        await CalcIssue.getBlFreightKrw({ arrBlNo: arrBlNo, eiCatCd: 'O' })
          .then(async response => {
            console.log('currency.billLocAmt>', response.data[this.info[idx].blNo][0].billLocAmt)
            this.info[idx].billLocAmt = response.data[this.info[idx].blNo][0].billLocAmt
        }).catch(e => {
         console.log(e)
         this.info[idx].billLocAmt = null
        })
      }
      let billLocAmt = this.$ekmtcCommon.isNotEmpty(this.info[idx].billLocAmt) ? parseFloat(this.info[idx].billLocAmt) : this.info[idx].billLocAmt

      if (currency === 'KRW') {
        this.info[idx].exKrw = this.$ekmtcCommon.isNotEmpty(billLocAmt) ? billLocAmt : parseInt(this.info[idx].krw) + parseInt((this.info[idx].usd * (this.info[idx].exRate * 10)) / 10)
        this.info[idx].exUsd = 0
      } else if (currency === 'KRW+USD') {
        this.info[idx].exKrw = this.info[idx].krw
        this.info[idx].exUsd = this.info[idx].usd
      }
      this.calcTotal()
    },
    numberFormat (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    emitData (type) {
        let res = null
        switch (type) {
          case 'info':
            res = {
              krwTotal: this.krwTotal,
              usdTotal: this.usdTotal,
              blList: this.info,
              blList2: this.parseBlList()
            }
            break
        }
        return res
    },
    parseBlList () {
      let blList = JSON.parse(JSON.stringify(this.info))
      let res = []
      for (let bl of blList) {
        if (bl.currency === 'KRW') {
          res.push(bl)
        } else if (bl.currency === 'KRW+USD') {
          let krwBl = JSON.parse(JSON.stringify(bl))
          krwBl.currency = 'KRW'
          krwBl.usd = 0
          let usdBl = JSON.parse(JSON.stringify(bl))
          krwBl.currency = 'USD'
          krwBl.krw = 0
          res.push(krwBl)
          res.push(usdBl)
        }
      }

      return res
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    },
    async recalculateFreightBillInfo () {
      let blArrStr = ''

      for (let i = 0; i < this.info.length; i++) {
        if (blArrStr !== '') {
          blArrStr += ','
        }

        blArrStr += this.info[i].blNo
      }

      let req = {
        blNo: blArrStr
      }

      await CalcIssue.recalculateFreightBillInfo(req).then(response => {
        console.log('Success')
      }).catch(e => {
        console.log(e)
      })
    }
  }
}
</script>
<style>
#fbp-body-table-div {
    overflow: auto;
    max-height: 340px;
  }

#fbp-body-table-div::-webkit-scrollbar {
  width: 3px;
  background: #fff;
}
#fbp-body-table-div::-webkit-scrollbar-thumb {
  background: #999;
}

#fbp-body-table-div .bb {
  border-bottom: 1px solid #c5c5c5;
}

</style>
